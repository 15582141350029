
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import smsMixin from '@/mixins/sms'
import GlobalMixin from '@/mixins/global'
import { mobileRegExp, emailRegExp, passwordRegExp } from '@/utils/reg'
import { Base64 } from 'js-base64'

@Component({
  name: 'modifyPassword',
  components: {
  },
  mixins: [smsMixin, GlobalMixin]
})
export default class PassportFindAccountPage extends Vue {
  @Provide() public account: string = ''
  @Provide() public code: string = ''
  @Provide() public firstPass: string = ''
  @Provide() public secondPass: string = ''
  @Provide() public apiError: string = ''
  @Provide() public waitTime: number = 2
  @Provide() public phone: string = ''
  @Provide() public firstPassInputType: string = 'password'
  @Provide() public secondPassInputType: string = 'password'

  @Provide() public autoFocus: boolean = false
  @Provide() public codeFocus: boolean = false
  @Provide() public firstPassFocus: boolean = false
  @Provide() public secondPassFocus: boolean = false

  @Emit()
  public created () {
    this.refresh()
  }

  @Emit()
  public async refresh () {
    try {
      const res = await httpHelper.get({
        url: 'Password/ResetChannel',
        data: {
          type: 1
        },
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.phone = res.data.phone || ''
      }
    } catch (err) {
      if (err.error === '107') {
        // 未绑定安全手机
        this.$toasted.show('绑定手机号后才可以修改登录密码，请先去绑定手机号哦')
        setTimeout(() => {
          this.$router.back()
        }, 1500)
      }
      this.apiError = err.message
    }
  }

  @Emit()
  public async sendCode () {
    let isSecend = 0
    const [err, res] = await this.sendV5SMSCode(this.phone, 6, 0)
    console.log(err, res)
    if (err && err.error && err.message) {
      this.apiError = err.message
    }
    (this.$refs.code as any).focus()
  }

  @Emit()
  public inputPassword (type: number) {
    const reg = /[\u4e00-\u9fa5]/g
    if (type === 1) {
      this.firstPass = this.firstPass.replace(reg, '')
    } else {
      this.secondPass = this.secondPass.replace(reg, '')
    }
  }

  @Emit()
  public passwordError () {
    this.apiError = ''
    setTimeout(() => {
      this.firstPassFocus = false
    }, 150)
    if (!this.firstPass) {
      this.apiError = '密码不能为空'
      return
    }
    if (passwordRegExp.test(this.firstPass)) {
      this.apiError = ''
    } else {
      this.apiError = '密码须为8-16位字母、数字、半角符号中至少2种组合'
    }
  }

  @Emit()
  public passwordSecondError () {
    this.apiError = ''
    setTimeout(() => {
      this.secondPassFocus = false
    }, 150)
    if (!this.secondPass) {
      this.apiError = '密码不能为空'
      return
    }
    if (!passwordRegExp.test(this.secondPass)) {
      this.apiError = '密码须为8-16位字母、数字、半角符号中至少2种组合'
      return
    }
    if (this.secondPass === this.firstPass) {
      this.apiError = ''
    } else {
      this.apiError = '两次输入的新密码不一致，请重新输入'
    }
  }

  @Emit()
  private inputBlur () {
    this.apiError = ''
    setTimeout(() => {
      this.codeFocus = false
    }, 150)
    if (!this.code) {
      this.apiError = '验证码不能为空'
    }
  }

  @Emit()
  private showPass (type: string) {
    if (type === 'firstPass') {
      this.firstPassInputType = this.firstPassInputType === 'password' ? 'text' : 'password'
    } else {
      this.secondPassInputType = this.secondPassInputType === 'password' ? 'text' : 'password'
    }
  }

  @Emit()
  public async submit () {
    if (!this.code) {
      this.apiError = '验证码不能为空'
      return
    }
    if (!this.firstPass) {
      this.apiError = '密码不能为空'
      return
    }
    if (!this.secondPass) {
      this.apiError = '密码不能为空'
      return
    }
    if (!passwordRegExp.test(this.firstPass)) {
      this.apiError = '密码须为8-16位字母、数字、半角符号中至少2种组合'
      return
    }
    if (!passwordRegExp.test(this.secondPass)) {
      this.apiError = '密码须为8-16位字母、数字、半角符号中至少2种组合'
      return
    }
    if (this.secondPass !== this.firstPass) {
      this.apiError = '两次输入的新密码不一致，请重新输入'
      return
    }
    if (this.apiError) {
      return
    }
    try {
      const res = await httpHelper.post({
        url: 'Password/Reset',
        data: {
          type: 1,
          smsCode: this.code,
          password: this.firstPass
        },
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.$toasted.show('修改成功')
        setTimeout(() => {
          this.$router.back()
        }, 1050)
      }
    } catch (err) {
      this.apiError = err.message
    }
  }

  @Emit()
  public goback () {
    this.$router.back()
  }
}
